import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, finalize, Observable, of, throwError} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from "../environments/environment";
import {CsrfTokenService} from "./csrf-token.service";
import {Router} from "@angular/router";
import {AccountData, AccountService} from "./account.service";

export interface AuthStatus {
    isLoggedIn: boolean;
    availableQuestions: number;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private loginUrl = `${environment.apiUrl}/auth/login`;
    private registerUrl = `${environment.apiUrl}/auth/register-user`;
    private logoutUrl = `${environment.apiUrl}/auth/logout`;
    private getRolesUrl = `${environment.apiUrl}/auth/get-roles`;
    private forgotPasswordUrl = `${environment.apiUrl}/auth/forgot-password`;
    private resetPasswordUrl = `${environment.apiUrl}/auth/reset-password`;

    private authStatus = new BehaviorSubject<AuthStatus>({
        isLoggedIn: false,
        availableQuestions: 0
    });

    private defaultEmptyAuthStatus: AuthStatus = {
        isLoggedIn: false,
        availableQuestions: 0
    };

    authStatus$ = this.authStatus.asObservable();

    constructor(
        private http: HttpClient,
        private permissionsService: NgxPermissionsService,
        private csrfTokenService: CsrfTokenService,
        private router: Router,
        private accountService: AccountService
    ) {
    }

    private post<T>(url: string, data: any): Observable<T> {
        return this.csrfTokenService.getCsrfToken().pipe(
            switchMap(() => this.http.post<T>(url, data, {withCredentials: true}))
        );
    }

    forgotPassword(data: { email: string }): Observable<any> {
        return this.post<any>(this.forgotPasswordUrl, data).pipe(
            catchError(error => {
                throw error;
            })
        );
    }

    resetPassword(data: { token: string, newPassword: string, confirmPassword: string }): Observable<any> {
        return this.post<any>(this.resetPasswordUrl, data).pipe(
            catchError(error => {
                throw error;
            })
        );
    }

    login(credentials: { email: string; password: string }): Observable<any> {
        return this.csrfTokenService.getCsrfToken().pipe(
            switchMap(() => this.http.post(this.loginUrl, credentials, {withCredentials: true})),
            switchMap(() => this.accountService.getPersonalData()),
            tap((data: AccountData) => {
                this.authStatus.next({
                    isLoggedIn: true,
                    availableQuestions: data.availableQuestions
                });
            }),
            catchError(error => {
                this.authStatus.next(this.defaultEmptyAuthStatus);
                throw error;
            })
        );
    }

    register(credentials: { email: string; password: string }): Observable<any> {
        return this.csrfTokenService.getCsrfToken().pipe(
            switchMap(() => this.http.post(this.registerUrl, credentials, {withCredentials: true})),
            tap((roles: any) => {
                this.authStatus.next({
                    isLoggedIn: true,
                    availableQuestions: 0
                });
                this.loadRoles(roles);
            }),
            catchError(error => {
                this.authStatus.next(this.defaultEmptyAuthStatus);
                throw error;
            })
        );
    }

    logout(): void {
        this.post<any>(this.logoutUrl, {})
            .pipe(
                catchError(() => of(null)),
                finalize(() => {
                    this.clearSession();
                    this.router.navigate(['/']);
                })
            )
            .subscribe();
    }

    public initializeAuthStatus(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.checkAuthStatus()
                .pipe(
                    switchMap(isLoggedIn => {
                        if (isLoggedIn) {
                            return this.accountService.getPersonalData().pipe(
                                tap((data: AccountData) => this.updateAuthStatusBasedOnAccountData(data)),
                                map(() => void 0),
                                catchError(error => {
                                    this.setLoggedOutStatus();
                                    return throwError(error);
                                })
                            );
                        } else {
                            this.setLoggedOutStatus();
                            return of(void 0);
                        }
                    })
                )
                .subscribe({
                    next: () => resolve(),
                    error: () => reject()
                });
        });
    }

    public clearSession(): void {
        localStorage.removeItem('csrfToken');
        this.permissionsService.flushPermissions();
        this.setLoggedOutStatus();
    }

    private updateAuthStatusBasedOnAccountData(data: AccountData): void {
        this.authStatus.next({
            isLoggedIn: true,
            availableQuestions: data.availableQuestions
        });
    }

    private setLoggedOutStatus(): void {
        this.authStatus.next(this.defaultEmptyAuthStatus);
    }

    private checkAuthStatus(): Observable<boolean> {
        return this.http.get<{ roles: string }>(this.getRolesUrl, {withCredentials: true}).pipe(
            map(response => {
                const roles = response.roles;
                this.permissionsService.flushPermissions();
                if (roles !== 'no-roles') {
                    this.loadRoles(roles);
                    return true;
                }
                return false;
            }),
            catchError(() => {
                this.permissionsService.flushPermissions();
                return of(false);
            })
        );
    }

    private loadRoles(rolesString: string): void {
        const rolesArray = rolesString.split(',');
        this.permissionsService.flushPermissions();
        this.permissionsService.loadPermissions(rolesArray);
    }
}
